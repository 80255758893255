import {useState} from "react";

import {useNavigate} from "react-router-dom";

import {callApi} from "../utils";

import "../../css/token-form.css";

const TokenForm = () => {
    const [token, setToken] = useState("");
    const [submitting, setSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const navigate = useNavigate();

    function onSubmit(event)
    {
        event.preventDefault();

        setSubmitting(true);

        callApi(
            "auth-token/", {
                method: "POST",
                data: {
                    token: token
                },
                onSuccess: () => {
                    navigate("/poll");
                },
                onError: errorMessage => {
                    setSubmitting(false);

                    setErrorMessage(errorMessage);
                }
            }
        )
    }

    return (
        <div>
            {
                errorMessage &&
                <span className="text-danger fw-bold">
                        {errorMessage}
                </span>
            }
            <form
                onSubmit={onSubmit}
                className="form-container">
                <input
                    required
                    type="text"
                    value={token}
                    className="form-control text-input-container"
                    onChange={event => {
                        setToken(event.target.value);

                        if(errorMessage)
                            setErrorMessage("");
                    }}
                    placeholder="Proszę podać token identyfikujący ankietę."
                />

                <button
                    type="submit"
                    disabled={!token || submitting}
                    className="btn btn-lg btn-primary"
                    onClick={onSubmit}
                >
                    Przejdź
                </button>
            </form>
        </div>
    );
}

export default TokenForm;
