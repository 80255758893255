import "../../../css/poll/questions.css";

const QuestionChoice = props => {
    let inputName = `question-${props.questionId}`

    return (
        <li className="list-group-item">
            <div className="form-check">
                <label
                    className="question-choice-label"
                >
                    {props.choice}

                    <input
                        type="radio"
                        name={inputName}
                        selected={props.selected}
                        onChange={props.onAnswered}
                        className="question-choice-input"
                    />
                </label>
            </div>
        </li>
    );
};

const QuestionChoices = props => {
    let questionId = props.questionId;
    let selectedChoice = props.selectedChoice;
    
    let choiceElements = props.choices.map((choice, i) => {
        return (
            <QuestionChoice
                key={i}
                choice={choice}
                questionId={questionId}
                onAnswered={
                    () => props.onAnswered(i)
                }
                selected={selectedChoice === choice}
            />
        );
    });

    return (
        <ul className="list-group">
            {choiceElements}
        </ul>
    );
};

const Question = props => {
    let question = props.question;

    let answer = question.answer;

    return (
        <div className="col-md-4 question-container">
            <div className="card card-primary">
                <h3 className="card-header">
                    {question.text}
                    {
                        question.required &&
                        <span className="text-danger fw-bold h2"> *</span>
                    }
                </h3>
                <div className="card-block">
                    <QuestionChoices
                        selectedChoice={answer}
                        questionId={question.id}
                        choices={question.choices}
                        onAnswered={props.onAnswered}
                    />
                </div>
            </div>
        </div>
    );
};

const Questions = props => {
    let questionElements = props.questions.map((question, i) => {
        question = {
            id: i,
            ...question
        };

        return (
            <Question
                key={i}
                question={question}
                onAnswered={
                    choiceId => props.onAnswered(
                        question.id, choiceId
                    )
                }
            />
        );
    });

    return (
        <div className="questions-container">
            {questionElements}
        </div>
    );
};

export default Questions;
