import {useState, useEffect} from "react";

import Loading from "../Loading";

import Questions from "./Questions";

import PollFinished from "./PollFinished";

import PollHomePage from "./PollHomePage";

import PageContentContainer from "../PageContentContainer";

import {callApi} from "../../utils";

const PollPage = () => {
    const [answers, setAnswers] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [pageData, setPageData] = useState(null);
    const [loadingErrorMessage, setLoadingErrorMessage] = useState(null);
    const [missingRequiredQuestionIds, setMissingRequiredQuestionIds] = useState([]);

    function fetchPageData({apiPath, method, data})
    {
        if(apiPath === undefined)
            apiPath = ""

        setAnswers([]);
        setLoaded(false);
        setPageData(null);
        setLoadingErrorMessage(null);
        setMissingRequiredQuestionIds([]);

        function onPageDataReceived(data)
        {
            let questions = data.questions;

            if(questions)
                setMissingRequiredQuestionIds(
                    questions.reduce((ids, question, i) => {
                        if(question.required)
                            ids.push(i);

                        return ids;
                    }, [])
                )

            setAnswers([]);
            setLoaded(true);
            setPageData(data);
        }

        callApi(
            apiPath, {
                data: data,
                method: method,
                onError: errorMessage => {
                    setLoaded(true);

                    console.error(errorMessage);

                    setLoadingErrorMessage(errorMessage);
                },
                onSuccess: onPageDataReceived
            }
        );
    }

    useEffect(() => {
        fetchPageData({
            method: "GET"
        });
    }, []);

    // https://stackoverflow.com/a/68933242
    // Display confirmation dialog when user tries to leave the page
    useEffect(() => {
        // only add the confirmation dialog if a poll is being displayed
        // (and when there is at least one choice selected)
        if(!pageData || pageData.home_page || pageData.finished || !answers.length)
            return;
        
        const unloadCallback = event => {
            event.preventDefault();
            event.returnValue = "";

            return "";
        };

        window.addEventListener("beforeunload", unloadCallback);

        return () => window.removeEventListener("beforeunload", unloadCallback);
    }, [pageData, answers.length]);

    if(!loaded)
        return (
            <PageContentContainer>
                <Loading/>
            </PageContentContainer>
        );

    if(!pageData)
        return (
            <PageContentContainer>
                <h1 className="text-danger fw-bold">
                    {loadingErrorMessage}
                </h1>
            </PageContentContainer>
        );

    function onQuestionAnswered(questionId, choiceId)
    {
        let answer = answers.find(
            answer => answer.question_id === questionId
        );

        if(answer)
            answer.choice_id = choiceId;
        else
        {
            let index = missingRequiredQuestionIds.indexOf(questionId);

            if(index !== -1)
            {
                missingRequiredQuestionIds.splice(index, 1);

                // update as copy to trigger re-render
                setMissingRequiredQuestionIds(
                    [...missingRequiredQuestionIds]
                );
            }

            answers.push({
                choice_id: choiceId,
                question_id: questionId
            });
        }

        // update as copy to trigger re-render
        setAnswers(
            [...answers]
        );
    }

    let homePageContent = pageData.home_page;

    let isHomePage = Boolean(homePageContent);

    let finished = pageData.finished;

    console.log(pageData, answers);

    const getPageElement = () => {
        let element = null;

        if(isHomePage)
            element = (
                <PollHomePage
                    pageContent={homePageContent}
                />
            );
        else if(finished)
            element = (
                <PollFinished/>
            );
        else
            element = (
                <Questions
                    questions={pageData.questions}
                    onAnswered={onQuestionAnswered}
                />
            );

        return element;
    };

    let pageNum = pageData.page_num;

    return (
        <PageContentContainer>
            {getPageElement()}
            {
                !finished &&
                <button
                    onClick={() => {
                        fetchPageData({
                            data: {
                                answers: answers
                            },
                            method: "POST",
                            apiPath: "next-page/"
                        });
                    }}
                    disabled={!isHomePage && missingRequiredQuestionIds.length}
                    className="btn btn-lg btn-primary"
                >
                    {
                        isHomePage ? "Rozpocznij ankietę" : (
                            pageData.max_page_num === pageNum ?
                                "Zakończ ankietę i prześlij odpowiedzi" :
                                "Zapisz odpowiedzi i przejdź na stronę " +
                                `${pageNum + 1}/${pageData.max_page_num}`
                        )
                    }
                </button>
            }
        </PageContentContainer>
    );
};

export default PollPage;
