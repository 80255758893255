import axios from "axios";

import ReactDOM from "react-dom/client";

import "bootstrap/dist/js/bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import "./css/index.css";

import App from "./js/App";

import {API_URL} from "./js/utils";

function updateAxiosDefaults()
{
    let axiosDefaults = axios.defaults;

    axiosDefaults.baseURL = API_URL;

    // without this setting cookies don't work properly
    axiosDefaults.withCredentials = true;
}

updateAxiosDefaults();

const root = ReactDOM.createRoot(
    document.getElementById("root")
);

root.render(
    <App/>
);
