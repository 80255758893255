import DOMPurify from "dompurify";

import "../../../css/poll/poll-home-page.css";

const PollHomePage = props => {
    let pageContent = DOMPurify.sanitize(props.pageContent);

    return (
        <div className="poll-home-page"
            dangerouslySetInnerHTML={{__html: pageContent}}
        />
    );
};

export default PollHomePage;
