import {HomePageLink} from "../HomePage";

const PollFinished = () => {
    return (
        <div>
            <h2 className="fw-bold">
                Ankieta została ukończona. Dziękujemy za wypełnienie.
            </h2>

            <HomePageLink/>
        </div>
    );
};

export default PollFinished;
