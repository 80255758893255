const PageContentContainer = props => {
    return (
        <div className="site-wrapper">
            <div className="site-wrapper-inner">
                <div className="container">
                    <div className="masthead clearfix">
                        {props.header}
                    </div>

                    <div className="inner cover">
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageContentContainer;
