import {BrowserRouter, Route, Routes} from "react-router-dom";

import {HomePage} from "./components/HomePage";

import PollPage from "./components/poll/PollPage";

import PageNotFound from "./components/PageNotFound";

import PollCompletionResults from "./components/results/PollCompletionResults";

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="/"
                    element={<HomePage/>}
                />
                <Route
                    path="/poll"
                    element={<PollPage/>}
                />
                <Route
                    path="/results"
                    element={<PollCompletionResults/>}
                />
                <Route
                    path="*"
                    element={<PageNotFound/>}
                />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
